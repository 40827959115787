import "./App.css";

import Navigation from "./sections/Navigation/Navigation";

import Home from "./views/Home/Home";
import Menu from "./views/Menu/Menu";
import About from "./views/About/About";
import PageNotFound from "./views/PageNotFound/PageNotFound";
import Reviews from "./views/Reviews/Reviews";
import ContactUs from "./views/ContactUs/ContactUs";

import AdminAccess from "./views/AdminAccess/AdminAccess";
import ScrollToTopOnRouteChange from "./ScrollToTopOnRouteChange";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import CopyrightFooter from "./sections/CopyrightFooter/CopyrightFooter";
import OrderNow from "./sections/OrderNow/OrderNow";


function App() {
	const isCurrentRouteAdmin = () => {
		const currentRoute = window.location.pathname.toLowerCase();
		return (
			currentRoute === "/admin_access" || currentRoute === "/adminaccess" 
		);
	};
	const renderNavigation = () => {
		return !isCurrentRouteAdmin() && <Navigation />;
	};

	const AllRoutes = [
		{ path: "/", element: <Home /> },
		{ path: "/menu", element: <Menu /> },
		{ path: "/about", element: <About /> },
		{ path: "/reviews", element: <Reviews /> },
		{ path: "/contact_us", element: <ContactUs /> },
		{ path: "/contact", element: <ContactUs /> },
		{ path: "/contactus", element: <ContactUs /> },
		{ path: "/admin_access", element: <AdminAccess /> },
		{ path: "/adminAccess", element: <AdminAccess /> },
		{ path: "*", element: <PageNotFound /> },
	];
	return (
		<BrowserRouter>
			<div className="App">
				{renderNavigation()}
				<ScrollToTopOnRouteChange />

				<Routes>
					{AllRoutes.map((route, index) => (
						<Route
							path={route.path}
							element={route.element}
							key={index}
						/>
					))}{" "}
					;
				</Routes>

				<CopyrightFooter />
                
				<OrderNow />
			</div>
		</BrowserRouter>
	);
}

export default App;